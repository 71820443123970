export default {
  appeal: {
    revoke: 'Revoke a claim ',
    revokeTip: "After withdrawing the suit, we default to lose the suit. We can't appeal again. Please confirm",
    RefutationOne: 'Refute a claim',
    RefutationZero: 'Withdraw appeal',
    appeanl1: 'Under appeal ',
    appeal1Status: 'I have appealed ',
    appeal1Tip: 'Wait for the platform to intervene ',
    appeal2: 'under appeal ',
    appeal3: 'Win the case ',
    appeal3Tip: 'You have won the case ',
    appeal4: 'Lost the case ',
    appeal4Tip: 'You have lost the case ',
    appeal2Status: 'You have been appealed ',
    appeal2Tip: 'Wait for the platform to intervene ',
    orderInfo: 'Order information ',
    amount: 'order amount ',
    actualAmount: 'Actual amount ',
    date: 'time ',
    orderNo: 'Order number ',
    orderType: 'Order type ',
    colMethod: 'collection method ',
    appealInfo: 'Appeal information ',
    appealType: 'Appeal type ',
    appealStatus: 'Appeal status ',
    appealCause: "I'm going to appealCause",
    voucher: 'voucher',
    tip: 'Tips',
    rebuttalReason: 'counterargument',
    warning: 'Warning: assets will be frozen after filing a complaint, and the platform will intervene in the transaction until the complaint is over. Malicious complaints will be classified as acts that disrupt the normal operation order of the platform, and accounts will be frozen if the circumstances are serious',
    noMore: 'No more ',
    confirmTip: 'Are you sure about this operation? ',
    confirm: 'confirm',
  },
  recharge: {
    orders: { // 组件订单
      orderNo: 'OrderNo',
      noMore: 'No more ',
      detail: 'View order ',
      completed: 'Completed ',
      cancel: 'has been cancelled ',
      toPay: 'Go pay ',
      inTransaction: 'transaction in progress ',
      payment: 'Confirm payment ',
      loading: 'Loading... ',
      errorText: 'There was an error... ',
    },
    appeal: {
      title: 'Appeal ',
      reason: 'Please select a reason ',
      warning: 'Please upload payment voucher ',
      palceholder: 'Please enter cause of complaint ',
      uploader: 'Add Screenshots ',
      tip: 'Please upload supplementary screenshots to solve it more efficiently for you ',
      appealConfirmTip: 'Whether to confirm the submission of the appeal',
      warn: 'Warning: assets will be frozen after filing a complaint, the platform will intervene in the transaction until the end of the complaint, malicious complaints will be classified as acts that disrupt the normal operation order of the platform, and the account will be frozen in serious cases',
      button: 'Confirm ',
    },
    cancel: {
      title: 'Cancelled ',
      status: 'Order cancelled ',
      tip: 'The deal is off ',
      info: 'Order information ',
      money: 'order amount ',
      date: 'time ',
      orderNo: 'Order number ',
      colMethod: 'collection method ',
      bank: 'Bank ',
      account: 'Account number',
      button: 'Appeal ',
      doubts: [
        'Order payment timeout caused order cancellation ',
        'Unsuccessful transfer or incorrect transfer ',
        'Upload payment voucher photo by mistake ',
        'The transfer amount does not match the order amount ',
        'In the absence of the above. Please click the appeal button at the bottom of the face to appeal, there will be platform staff to deal with the problem for you',
      ],
    },
    completed: {
      title: 'Completed ',
      status: 'Order completed ',
      tip: 'Seller has confirmed payment ',
      info: 'Order information ',
      money: 'order amount ',
      date: 'time ',
      orderNo: 'Order number ',
      colMethod: 'collection method ',
      bank: 'Bank ',
      account: 'Account number',
      voucher: 'voucher of payment ',
      voucherTip: 'Payment credentials you have uploaded ',
      imageTip: 'Click to view larger image ',
      button: 'Appeal ',
    },
    inTransaction: {
      title: 'Transaction underway ',
      status: 'Order transaction ',
      tip: 'Wait for seller to confirm payment ',
      orderRealMoney: 'Actual amount ',
      info: 'Order information ',
      money: 'order amount ',
      date: 'time ',
      orderNo: 'Order number ',
      colMethod: 'collection method ',
      bank: 'Bank ',
      account: 'Account number',
      voucher: 'voucher of payment ',
      voucherTip: 'Payment credentials you have uploaded ',
      imageTip: 'Click to view larger image ',
      button: 'Appeal ',
    },
    transaction: {
      title: 'Transaction underway ',
      status: 'Order transaction ',
      tip: 'Please check the top-up information and pay in time ',
      info: 'Order information ',
      money: 'order amount ',
      orderRealMoney: 'Actual amount ',
      date: 'time ',
      orderNo: 'Order number ',
      colMethod: 'collection method ',
      bank: 'Bank ',
      account: 'Account number',
      voucher: 'Please upload payment voucher ',
      voucherTip: 'Submitting payment vouchers helps us confirm your order as soon as possible ',
      voucherWarn: 'Please upload payment vouchers ',
      confirmTip: 'Whether to confirm payment?',
      cannel: 'Cancel the order ',
      cannelTip: 'Confirm whether to cancel the order?',
      confirm: 'Confirm payment ',
    },
  },
  withdrawal: {
    appeal: {
      title: 'Appeal ',
      reason: 'Please select a reason ',
      warning: 'Please upload payment voucher ',
      palceholder: 'Please enter cause of complaint ',
      uploader: 'Add Screenshots ',
      tip: 'Please upload supplementary screenshots to solve it more efficiently for you ',
      appealConfirmTip: 'Whether to confirm the submission of the appeal',
      warn: 'Warning: assets will be frozen after filing a complaint, the platform will intervene in the transaction until the end of the complaint, malicious complaints will be classified as acts that disrupt the normal operation order of the platform, and the account will be frozen in serious cases',
      button: 'Confirm the complaint'
    },
    cancel: {
      title: 'Cancelled ',
      status: 'Order cancelled ',
      tip: 'The deal is off ',
      info: 'Order information ',
      money: 'order amount ',
      date: 'time ',
      orderNo: 'Order number ',
      colMethod: 'collection method ',
      bank: 'Bank ',
      account: 'Account number',
      button: 'Appeal ',
      doubts: [
        'Order payment timeout caused order cancellation ',
        'Unsuccessful transfer or incorrect transfer ',
        'Upload payment voucher photo by mistake ',
        'The transfer amount does not match the order amount ',
        'In the absence of the above. Please click the appeal button at the bottom of the face to appeal, there will be a platform staff to deal with the problem for you '
      ],
    },
    completed: {
      title: 'Completed ',
      status: 'Order completed ',
      tip: 'The deal is done ',
      info: 'Order information ',
      money: 'order amount ',
      date: 'time ',
      orderNo: 'Order number ',
      payInfo: 'Payment method ',
      voucher: 'voucher of payment ',
      imageTip: 'Click to view larger image ',
      button: 'Appeal ',
    },
    transaction: {
      title: 'Transaction underway ',
      status: 'Order transaction ',
      tip: 'Withdrawal process ',
      info: 'Order information ',
      money: 'order amount ',
      orderRealMoney: 'Actual amount ',
      date: 'time ',
      orderNo: 'Order number ',
      Refutation: 'Appeal',
      RefutationZero: 'Appeal Details',
      payment: 'payment method',
      payperk: 'payment info',
      payperkTxt: 'Buyer’s payment voucher',
      imageTip: 'Click to view larger image '
    },
  },
  http: {
    error: 'Network exception'
  },
};
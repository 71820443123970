import request from './request';

// 登录
export function login(query) {
  return request({
    url:  '/cashier/oauth2/accessToken',
    method: 'post',
    data: query
  })
}

export function userRec(query) {
  return request({
    url:  '/cashier/api/userRec',
    method: 'post',
    data: query
  })
}
export function userDraw2(query) {
  return request({
    url:  '/cashier/api/userDraw',
    method: 'post',
    data: query
  })
}

export function getCashierConfig() {
  return request({
    url:  '/cashier/common/getCashierConfig',
    method: 'get',
  })
}

// 用户充值网络下拉框
export function ustdTypeList() {
  return request({
    url:  '/cashier/common/getWalletWay',
    method: 'get',
  })
}

// 用户充值列表
export function userRecList(query = {}) {
  return request({
    url:  '/cashier/userRecList',
    method: 'get',
    params: query
  })
}

// 充值订单详情
export function userRecOrderDetails(query) {
  return request({
    url:  `/cashier/userRecOrderDetails/${query}`,
    method: 'get',
  })
}

// 用户上传图片
export function upload(data) {
  return request({
    url:  `/cashier/common/upload`,
    method: 'post',
    headers: {
      "Content-Type": 'multipart/form-data; boundary=something',
    },
    data
  })
}

// TODO 此接口已废弃，联系后端开发返回S3路径
// 用户获取图片
export function getImg(data) {
  return request({
    url:  `/cashier/common/getFile`,
    method: 'get',
    params: data
  })
}

// 商户用户确认付款
export function userRecOrderConfirmPay(data) {
  return request({
    url:  `/cashier/userRecOrderConfirmPay`,
    method: 'post',
    data
  })
}

// 商户用户取消订单
export function userRecOrderCancelOrder(query) {
  return request({
    url:  `/cashier/userRecOrderCancelOrder/${query}`,
    method: 'post',
  })
}

// 用户提现
export function userDraw(query) {
  return request({
    url:  `/cashier/userDraw`,
    method: 'post',
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    },
    data:query
  })
}

// 用户提现列表
export function listCashier(params) {
  return request({
    url:  `/cashier/list`,
    method: 'get',
    params
  })
}

//  用户提现详情
export function detailCashier(mchOrderNo) {
  return request({
    url:  `/cashier/detail/${mchOrderNo}`,
    method: 'get',
  })
}

// 申诉列表
export function appealList(params) {
  return request({
    url:  `/cashier/appealList`,
    method: 'get',
    params
  })
}

// 充值发起申诉
export function commitRecAppeal(data) {
  return request({
    url:  `/cashier/commitRecAppeal`,
    method: 'post',
    data
  })
}

// 充值申诉原因列表
export function recAppealReason(mchOrderNo) {
  return request({
    url:  `/cashier/recAppealReason/${mchOrderNo}`,
    method: 'get',
  })
}

// 充值申诉详情
export function recAppealDetail(mchOrderNo) {
  return request({
    url:  `/cashier/recAppealDetail/${mchOrderNo}`,
    method: 'get',
  })
}

// 充值撤销详情
export function cancelRecAppeal(mchOrderNo) {
  return request({
    url:  `/cashier/cancelRecAppeal/${mchOrderNo}`,
    method: 'get',
  })
}


// 提现申诉详情
export function drawAppealDetail(mchOrderNo) {
  return request({
    url:  `/cashier/drawAppealDetail/${mchOrderNo}`,
    method: 'get',
  })
}

// 提现申诉原因列表
export function drawAppealReason(mchOrderNo) {
  return request({
    url:  `/cashier/drawAppealReason/${mchOrderNo}`,
    method: 'get',
  })
}

// 提现发起/申诉
export function commitDrawAppeal(data) {
  return request({
    url:  `/cashier/commitDrawAppeal`,
    method: 'post',
    data
  })
}


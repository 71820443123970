import * as VueRouter from 'vue-router'
const RouterView = { template: '<router-view></router-view>' }

const routes = [
  {
    path: '/recharge',
    component: RouterView,
    children: [
      {
        path: '',
        name: 'RechargeIndex',
        component: () => import('@/views/recharge/index'),
      },
      {
        path: 'list',
        name: 'RechargeList',
        component: () => import('@/views/recharge/list'),
      },
      {
        path: 'search',
        name: 'RechargeSearch',
        component: () => import('@/views/recharge/search'),
      },
      {
        path: 'transaction',
        name: 'RechargeTransaction',
        component: () => import('@/views/recharge/transaction'),
      },
      {
        path: 'inTransaction',
        name: 'RechargeInTransaction',
        component: () => import('@/views/recharge/inTransaction'),
      },
      {
        path: 'cancel',
        name: 'RechargeCancel',
        component: () => import('@/views/recharge/cancel'),
      },
      {
        path: 'completed',
        name: 'RechargeCompleted',
        component: () => import('@/views/recharge/completed'),
      },
      {
        path: 'appeal',
        name: 'RechargeAppeal',
        component: () => import('@/views/recharge/appeal'),
      }
    ]
  },
  {
    path: '/appealRecord',
    component: RouterView,
    children: [
      {
        path: 'list',
        name: 'AppealRecordList',
        component: () => import('@/views/appealRecord/list'),
      },
      {
        path: 'search',
        name: 'AppealRecordSearch',
        component: () => import('@/views/appealRecord/search'),
      },
      {
        path: 'rechargeAppeal',
        name: 'AppealRechargeAppeal',
        component: () => import('@/views/appealRecord/rechargeAppeal'),
      },
      {
        path: 'withdrawalAppeal',
        name: 'AppealWithdrawalAppeal',
        component: () => import('@/views/appealRecord/withdrawalAppeal'),
      },
    ]
  }, {
    path: '/withdrawal',
    component: RouterView,
    children: [
      {
        path: 'list',
        name: 'WithdrawalList',
        component: () => import('@/views/withdrawal/list'),
      },
      {
        path: 'search',
        name: 'WithdrawalSearch',
        component: () => import('@/views/withdrawal/search'),
      },
      {
        path: 'transaction',
        name: 'WithdrawalTransaction',
        component: () => import('@/views/withdrawal/transaction'),
      },
      {
        path: 'completed',
        name: 'WithdrawalCompleted',
        component: () => import('@/views/withdrawal/completed'),
      },
      {
        path: 'cancel',
        name: 'WithdrawalCancel',
        component: () => import('@/views/withdrawal/cancel'),
      },
      {
        path: '',
        name: 'WithdrawalIndex',
        component: () => import('@/views/withdrawal/index'),
      },
      {
        path: 'appeal',
        name: 'WithdrawalAppeal',
        component: () => import('@/views/withdrawal/appeal'),
      },
    ]
  },
  {
    path:"/",
    name:'Home',
    component: () => import('@/views/home'),
  }
]
const routerInstace = VueRouter.createRouter({
  history: VueRouter.createWebHashHistory(),
  routes
})

export default routerInstace;
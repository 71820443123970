export default {
  appeal: {
    revoke: '撤销申诉',
    revokeTip: '撤诉后默认败诉，无法再进行申诉，请确认',
    RefutationOne: '反驳申诉',
    RefutationZero: '撤销申诉',
    appeal1: '申诉中',
    appeal1Status: '我已申诉',
    appeal1Tip: '等待平台介入',
    appeal2: '被申诉中',
    appeal3: '胜诉',
    appeal3Tip: '您已胜诉',
    appeal4: '败诉',
    appeal4Tip: '您已败诉',
    appeal2Status: '您已被申诉',
    appeal2Tip: '等待平台介入',
    orderInfo: '订单信息',
    amount: '订单金额',
    actualAmount: '实际金额',
    date: '时间',
    orderNo: '订单号',
    orderType: '订单类型',
    colMethod: '收款方式',
    appealInfo: '申诉信息',
    appealType: '申诉类型',
    appealStatus: '申诉状态',
    appealReason: '申诉理由',
    appealCause: '申诉原因',
    voucher: '凭证',
    rebuttalReason: '反驳理由',
    warning: '警告：提起申诉后资产将会冻结，平台将介入这笔交易，直到申诉结束，恶意申诉届于扰乱平台正常运营秩序的行为，情节严重者将冻结账户',
    noMore: '没有更多了',
    tip: '提示',
    appealConfirmTip: '是否确认提交申诉',
    confirmTip: '确认进行此次操作吗？',
    confirm: '确认',
  },
  recharge: {
    orders: { // 组件订单
      orderNo: '订单号',
      noMore: '没有更多了',
      detail: '查看订单',
      completed: '已完成',
      cancel: '已取消',
      toPay: '去支付',
      inTransaction: '交易中',
      payment: '确认收款',
      loading: '加载中...',
      errorText: '出错了...',
    },
    appeal: {
      title: '申诉',
      appealConfirmTip: '是否确认提交申诉',
      reason: '请选择原因',
      warning: '请上传支付凭证',
      palceholder: '请输入申诉原因',
      uploader: '补充截图',
      tip: '请上传补充截图，更高效地为您解决',
      warn: '警告：提起申诉后资产将会冻结，平台将介入这笔交易，直到申诉结束，恶意申诉届于扰乱平台正常运营秩序的行为，情节严重者将冻结账户',
      button: '确认'
    },
    cancel: {
      title: '已取消',
      status: '订单已取消',
      tip: '充值已取消',
      info: '订单信息',
      money: '订单金额',
      date: '时间',
      orderNo: '订单号',
      colMethod: '收款方式',
      bank: '银行',
      account: '账号',
      button: '申诉',
      doubts: ['订单付款超时导致订单取消', '未成功转账或转账失误', '错误上传支付凭证照片', '转账金额与订单金额不符', '如无以上情况。请点击面底部申诉按钮进行申诉,将会有平台人员为您处理问题'],
    },
    completed: {
      title: '已完成',
      status: '订单已完成',
      tip: '卖家已确认收款',
      info: '订单信息',
      money: '订单金额',
      date: '时间',
      orderNo: '订单号',
      colMethod: '收款方式',
      bank: '银行',
      account: '账号',
      voucher: '支付凭证',
      voucherTip: '您已上传的支付凭证',
      imageTip: '点击查看大图',
      button: '申诉',
    },
    inTransaction: {
      title: '交易中',
      status: '订单交易中',
      tip: '等待卖家确认收款',
      orderRealMoney: '实际金额',
      info: '订单信息',
      money: '订单金额',
      date: '时间',
      orderNo: '订单号',
      colMethod: '收款方式',
      bank: '银行',
      account: '账号',
      voucher: '支付凭证',
      voucherTip: '您已上传的支付凭证',
      imageTip: '点击查看大图',
      button: '申诉',
    },
    transaction: {
      title: '交易中',
      status: '订单交易中',
      tip: '请您及时核对充值信息并付款',
      info: '订单信息',
      money: '订单金额',
      orderRealMoney: '实际金额',
      date: '时间',
      orderNo: '订单号',
      colMethod: '收款方式',
      bank: '银行',
      account: '账号',
      voucher: '请上传支付凭证',
      voucherTip: '提交付款凭证可以帮助我们尽快确认您的订单',
      voucherWarn: '请上传支付凭证',
      confirmTip: '是否确认付款?',
      cannel: '取消订单',
      cannelTip: '是否确认取消订单?',
      confirm: '确认付款',
    },
  },
  withdrawal: {
    appeal: {
      title: '申诉',
      reason: '请选择原因',
      warning: '请上传支付凭证',
      palceholder: '请输入申诉原因',
      uploader: '补充截图',
      tip: '请上传补充截图，更高效地为您解决',
      appealConfirmTip: '是否确认提交申诉',
      warn: '警告：提起申诉后资产将会冻结，平台将介入这笔交易，直到申诉结束，恶意申诉届于扰乱平台正常运营秩序的行为，情节严重者将冻结账户',
      button: '确认申诉'
    },
    cancel: {
      title: '已取消',
      status: '订单已取消',
      tip: '交易已取消',
      info: '订单信息',
      money: '订单金额',
      date: '时间',
      orderNo: '订单号',
      colMethod: '收款方式',
      bank: '银行',
      account: '账号',
      button: '申诉',
      doubts: ['订单付款超时导致订单取消', '未成功转账或转账失误', '错误上传支付凭证照片', '转账金额与订单金额不符', '如无以上情况。请点击面底部申诉按钮进行申诉,将会有平台人员为您处理问题'],
    },
    completed: {
      title: '已完成',
      status: '订单已完成',
      tip: '交易已完成',
      info: '订单信息',
      money: '订单金额',
      date: '时间',
      orderNo: '订单号',
      payInfo: '收款方式',
      voucher: '支付凭证',
      imageTip: '点击查看大图',
      button: '申诉',
    },
    transaction: {
      title: '交易中',
      status: '订单交易中',
      tip: '提现处理中',
      info: '订单信息',
      money: '订单金额',
      orderRealMoney: '实际金额',
      date: '时间',
      orderNo: '订单号',
      Refutation: '申诉',
      RefutationZero: '申诉详情',
      payment: '收款方式',
      payperk: '付款信息',
      payperkTxt: '买家支付凭证',
      imageTip: '点击查看大图'
    },
  },
  http: {
    error: '网络异常'
  },
};
import { createApp } from 'vue/dist/vue.esm-bundler.js'
import App from './App.vue'
import { createI18n } from "vue-i18n/index";
import zh from "@/language/lang/zh"
import en from "@/language/lang/en"
import router from './router'
import 'vant/lib/index.css';
import { getToken } from '@/utils';
getToken();
const app = createApp(App)
const i18n = createI18n({
  legacy: false,
  locale: zh, // 初始化配置语言
  messages: {
    zh,
    en,
  },
});
app.use(i18n)
app.use(router)
app.mount('#app')
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { ref } from "vue";
import { ConfigProvider as VanConfigProvider } from "vant";
export default {
  __name: 'App',
  setup(__props) {
    const themeVars = ref({
      // 输入框样式
      cellBackground: "#F6F6F6",
      cellVerticalPadding: "13px",
      cellHorizontalPadding: "12px",
      cellFontSize: "16px",
      // 按钮样式
      buttonPrimaryColor: "#1D2129",
      buttonPrimaryBackground: "#FBCD2A",
      buttonPrimaryBorderColor: "transparent",
      // tab样式
      tabsLineHeight: "42px",
      tabsBottomBarColor: "#FBCD2A",
      // 搜索栏
      searchContentBackground: "#EBECF0",
      searchInputHeight: "36px",
      // 单选框
      radioLabelColor: "#6F7B8B"
    });
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      return _openBlock(), _createBlock(_unref(VanConfigProvider), {
        "theme-vars": themeVars.value
      }, {
        default: _withCtx(() => [_createVNode(_component_router_view)]),
        _: 1
      }, 8, ["theme-vars"]);
    };
  }
};
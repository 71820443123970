import ClipboardJS from "clipboard";
import { showFailToast,showSuccessToast  } from "vant";
import crypto from 'crypto-js';
import {login} from '../api';
export const $copy = () => {
  const copyBtn = new ClipboardJS('.copy');
  copyBtn.on('success', () => {
    showSuccessToast('复制成功');
  })
  copyBtn.on('error', () => {
    showFailToast('复制失败');
  })

};

/**
* 参数处理
* @param {*} params  参数
*/
export function tansParams(params) {
  let result = ''
  for (const propName of Object.keys(params)) {
    const value = params[propName];
    var part = propName + "=";
    if (value !== null && value !== "" && typeof (value) !== "undefined") {
      if (typeof value === 'object') {
        for (const key of Object.keys(value)) {
          if (value[key] !== null && value[key] !== "" && typeof (value[key]) !== 'undefined') {
            let params = propName + '[' + key + ']';
            var subPart = params + "=";
            result += subPart + value[key] + "&";
          }
        }
      } else {
        result += part + value + "&";
      }
    }
  }
  return result
}


/**
 * 通用js方法封装处理
 * 将标准时间转化为本地时间
 */

// 日期格式化
export function parseTime(time, pattern) {
  if (arguments.length === 0 || !time) {
    return null
  }
  const format = pattern || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
      time = parseInt(time)
    } else if (typeof time === 'string') {
      time = time.replace(new RegExp(/-/gm), '/').replace('T', ' ').replace(new RegExp(/\.[\d]{3}/gm), '');
    }
    if ((typeof time === 'number') && (time.toString().length === 10)) {
      time = time * 1000
    }
    // 本地时区偏移量
    // const offset = (0 - new Date().getTimezoneOffset()) * 60 * 1000;
    date = new Date(time);
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value] }
    if (result.length > 0 && value < 10) {
      value = '0' + value
    }
    return value || 0
  })
  return time_str
}

// 倒计时格式化
export const formatterDownTime = (date) => {
  if (date.toString().length === 1) {
    return "0" + date;
  }
  return date;
};



const KEY = 'x7vx19u5ve0ro29ahbqllf9ook8jbo6d';
// 加密
export const aseEncode = (word) => {
  return crypto.SHA256(word).toString();
}

export const aseEncodeMD5 = (word) => {
  const hash = crypto.MD5(word);
  return crypto.enc.Hex.stringify(hash).toUpperCase();
}

// 解密
export const aseDecode = (word) => {
  let key = crypto.enc.Utf8.parse(KEY);
  let srcs = crypto.enc.Base64.stringify(crypto.enc.Hex.parse(word));
  let decrypt = crypto.AES.decrypt(srcs, key, {  mode: crypto.mode.ECB, padding: crypto.pad.Pkcs7 });
  let decryptedStr = decrypt.toString(crypto.enc.Utf8);
  return decryptedStr.toString();
}

// 获取权限token
export const getToken = async (clientId, mchKeys) => {
  const clientIdStorage = localStorage.getItem('mchId');
  const mchKeyStorage = localStorage.getItem('mchKey');
  const mchKey = aseEncodeMD5(mchKeys || mchKeyStorage);
  const params = {
    clientId: clientId || clientIdStorage,
    grantType: 'access_token',
    mchKey: mchKey,
    timestamp: parseTime(new Date()).toString(),
  }
  params.sign = aseEncode(tansParams(params)+ 'mchKey=' + mchKey);
  const res = await login(params)
  localStorage.setItem('token', res.data.accessToken);
  return res.data.accessToken;
}

import axios from 'axios';
import { getToken, tansParams } from '../utils';
import { showFailToast } from 'vant';
import { getLocale } from "../language";

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// 创建axios实例
const request = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: "https://merchant-api.gamevip.vip/",
  //baseURL: process.env.VUE_APP_BASE_API,
  // 超时
  timeout: 60000
})


// request拦截器
request.interceptors.request.use(async (config) => {
  let token = localStorage.getItem('token');
  if (!token && !config.url.includes('accessToken')) {
    token = await getToken();
  }
  config.headers['accessToken'] = token // 让每个请求携带自定义token 请根据实际情况自行修改
  config.headers['lang'] = getLocale()

  // get请求映射params参数
  if (config.method === 'get' && config.params) {
    let url = config.url + '?' + tansParams(config.params);
    url = url.slice(0, -1);
    config.params = {};
    config.url = url;
  }

  return config
}, error => {
  Promise.reject(error)
})

// 响应拦截器
request.interceptors.response.use((res) => {
  const data = res.data;
  let msg;
  if (data.code === 200) {
    return data;
  } else if (data.code === 500) {
    // eslint-disable-next-line no-undef
    msg = data?.msg || '网络错误'
    showFailToast(msg);
  }else {
	msg = data.msg || '网络连接超时，请稍后再试'
    showFailToast(msg);
  }

  throw new Error(msg);
}, error => {
  showFailToast("网络连接超时，请稍后再试");

  return Promise.reject(error);
})

export default request
